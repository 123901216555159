import moment from 'moment';
import { PROCURA_START_DATE } from './constants';

export const downloadFileFromResponse = (res, filename) => {
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

export const getStandardDateRange = (
  range: StandardDateRange,
): [Date, Date] => {
  const today = moment().set({
    hour: 5,
    minute: 30,
    second: 0,
    millisecond: 0,
  });
  const yesterday = today.clone().subtract(1, 'days');
  const tomorrow = today.clone().add(1, 'days');
  const lastWeek = today.clone().subtract(1, 'weeks');
  const lastMonth = today.clone().subtract(1, 'months');
  const lastMonthMinus1 = yesterday.clone().subtract(1, 'months');
  const lastWeekMinus1 = yesterday.clone().subtract(1, 'week');
  const currentMonth = today.clone().startOf('month');

  if (range === StandardDateRange.TODAY) {
    return [today.toDate(), today.toDate()];
  }

  if (range === StandardDateRange.YESTERDAY) {
    return [yesterday.toDate(), yesterday.toDate()];
  }

  if (range === StandardDateRange.LAST_WEEK_TO_YESTERDAY) {
    return [lastWeekMinus1.toDate(), yesterday.toDate()];
  }

  if (range === StandardDateRange.LAST_WEEK_TO_TODAY) {
    return [lastWeek.toDate(), today.toDate()];
  }

  if (range === StandardDateRange.LAST_MONTH_TO_YESTERDAY) {
    return [lastMonthMinus1.toDate(), yesterday.toDate()];
  }

  if (range === StandardDateRange.LAST_MONTH_TO_TOMORROW) {
    return [lastMonthMinus1.toDate(), tomorrow.toDate()];
  }

  if (range === StandardDateRange.LAST_MONTH_TO_TODAY) {
    return [lastMonth.toDate(), today.toDate()];
  }

  if (range === StandardDateRange.CURRENT_MONTH_TO_TODAY) {
    return [currentMonth.toDate(), today.toDate()];
  }

  if (range === StandardDateRange.CURRENT_MONTH_TO_YESTERDAY) {
    return [currentMonth.toDate(), yesterday.toDate()];
  }

  if (range === StandardDateRange.START_OF_YEAR_TO_TODAY) {
    return [
      today.clone().startOf('year').toDate(),
      today.endOf('day').toDate(),
    ];
  }

  if (range === StandardDateRange.SINCE_THE_BEGINNING) {
    return [
      moment(PROCURA_START_DATE).startOf('year').toDate(),
      moment().toDate(),
    ];
  }

  return [today.toDate(), today.toDate()];
};

export enum StandardDateRange {
  TODAY,
  YESTERDAY,
  LAST_WEEK_TO_YESTERDAY,
  LAST_WEEK_TO_TODAY,
  LAST_MONTH_TO_YESTERDAY,
  LAST_MONTH_TO_TODAY,
  LAST_MONTH_TO_TOMORROW,
  CURRENT_MONTH_TO_TODAY,
  CURRENT_MONTH_TO_YESTERDAY,
  START_OF_YEAR_TO_TODAY,
  SINCE_THE_BEGINNING,
}

export const DATE_RANGE_OPTIONS = Object.values(StandardDateRange).reduce(
  (acc, range: StandardDateRange) => {
    acc[range] = getStandardDateRange(range);
    return acc;
  },
  {},
);

export const formatCurrency = (value: number): string => {
  return (+value || 0).toLocaleString('en-IN', {
    currency: 'INR',
    style: 'currency',
    maximumFractionDigits: 0,
  });
};

export const isDemoEnv = () => {
  if (process.env.NEXT_PUBLIC_NODE_ENV === 'demo') {
    return true;
  }
  return false;
};
